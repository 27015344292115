import React from "react";
import { CheckIcon, XIcon} from '@heroicons/react/outline'

const tiers = [
    {
      name: 'Agent',  
      icon: XIcon,
      iconclassname: 'h-6 w-6 text-red-600',
      features: [
        '30-60 +/- days after accepting buyer’s offer.',
        'Find the right real estate agent.',
        'List property for 30-60 days.',
        '6% on average is paid by you, the seller.',
        'Coordinate with tenants for showing.',
        'Once an offer is accepted provide disclosures, reports.',
        'The sale is often subject to appraisal and inspection.',
        'Find a contractor to repair necessary items from home inspection and appraisal.',
        'Risk of buyer financing falling through.',
      ],
    },
    {
      name: 'Brandon w/ Sell Fast Sac',  
      icon: CheckIcon,
      iconclassname: 'h-6 w-6 text-accent-600',
      features: [
        'Close date of YOUR choice.',
        'No listing, showings or coordinating tenant schedules.',
        'No fees, taxes, or commissions which saves you $15,000-$25,000.',
        'Just one walkthrough.',
        'Once the offer is accepted, our team will handle the rest. You can relax and close when you choose.',
        'All contracts are California legally approved real estate contracts.',
        'No appraisal or home inspection needed.',
        'No repairs needed, as-is sale.',
      ],
    },
  ]

  
export default function AgentvsSellFastSactable() {
    return (


        <div> 
        <div className="relative">
        
          <div className="relative z-10 container mx-auto px-6 lg:px-8 py-8">
          {/* <span className="text-3xl font-semibold text-shadow text-center block mb-4 text-brand-600 font-display">{tiers[0].name} vs {tiers[1].name}</span> */}
            <div className="container mx-auto lg:max-w-6xl space-y-4  lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
           
              {tiers.map((tier) => (
                <div key={tier.name} className="flex flex-col rounded-lg overflow-hidden bg-gray-100 pb-2">
                   <span className="font-semibold text-xl md:text-2xl font-display text-brand-600 text-center mt-2 pt-4">{tier.name}</span>
                  <div className="flex-1 flex flex-col justify-between ">
                
                    <ul className="space-y-4 px-6 ml-0">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                              <tier.icon className={tier.iconclassname}/> 
                          </div>
                          <p className="ml-3 text-base text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                     
                  </div>
                </div>
              ))}
            </div>
          </div>
      
       
      </div> </div>
    )}